@import url('https://fonts.googleapis.com/css2?family=Quantico&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#header {
  min-height: 80vh;
}

html {
  scroll-behavior: smooth;
}

.feature {
  font-size: 0.85rem;
}

.ip {
  font-family: 'Quantico', sans-serif;
}

.custom-shape-divider-bottom-1649766969 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1649766969 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 75px;
}

.custom-shape-divider-bottom-1649766969 .shape-fill {
  fill: #151719;
}

.container {
  width: 80vw;
}

@layer components {
  .btn {
    @apply inline-block px-6 py-2 font-semibold text-center text-white rounded-sm transition duration-150 ease-in-out;
  }

  .btn-tall {
    @apply py-3;
  }

  .title {
    @apply mb-4 text-3xl font-extrabold text-center text-white;
  }

  .title-small {
    @apply text-2xl font-bold;
  }

  .intro {
    @apply text-xl text-gray-500 text-center mx-8 mb-12 max-w-sm;
  }

  .text {
    @apply text-xl text-gray-500;
  }
}

@layer utilities {
  .bg-illustration-01 {
    background-image: url("../img/illustration-section-01.svg");
  }

  .bg-illustration-02 {
    background-image: url("../img/illustration-section-02.svg");
  }
}
